<template>
  <div class="presentation-item-container">
    <div class="icon-and-title">
      <slot></slot>
      <div class="title">{{ title }}</div>
    </div>
    <div class="text" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: "PresentationItem",
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    iconPath: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.presentation-item-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  .icon-and-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Nunito;
    font-weight: bold;
    color: #405f9a;
    font-size: 1.25rem;
    .title {
      margin-left: 0.5rem;
    }
    margin-bottom: 0.5rem;
  }
  .text {
    font-family: NunitoLight;
    font-size: 1rem;
  }
}
</style>
<template>
  <div id="app">
    <b-container fluid>
      <b-row align-v="stretch">
        <b-col xl="5" class="left-container d-none d-xl-block">
          <div class="mini-container">
            <welcome-card></welcome-card>
          </div>
          <policies></policies>
        </b-col>
        <b-col col xl="7" class="right-container d-md-block">
          <div class="logo-container">
            <img class="logo" src="../assets/logo.svg" alt="" />
          </div>
          <div class="presentation-container-desktop">
            <div class="presentation-column">
              <div>
                <img
                  class="presentation-img"
                  src="../assets/presentation.png"
                  alt=""
                />
              </div>
              <div class="presentation-items">
                <presentation-item
                  title="Start a conversation"
                  text="Feel free to start a conversation about anything and see it grow everyday"
                >
                  <img class="desktop-icon" src="../assets/start.png" alt="" />
                </presentation-item>
                <presentation-item
                  title="Contribute"
                  text="Express your opinions in conversations that you find interesting"
                >
                  <img
                    class="desktop-icon"
                    src="../assets/contribute.png"
                    alt=""
                  />
                </presentation-item>
                <presentation-item
                  title="Listen"
                  text="Listen to conversations as you would listen to a collaborative podcast. Interact with a message in order to switch the track. A conversation can have an unlimited number of tracks and can be <b>listened in many ways</b> without changing the context."
                >
                  <img class="desktop-icon" src="../assets/listen.png" alt="" />
                </presentation-item>
              </div>
            </div>
          </div>
          <div class="presentation-container-mobile">
            <div class="title">
              The first audio based, topic centered, community shaped social
              network that aims to remove unwanted noise from conversations.
            </div>
            <div class="social-media-mobile">
              <img
                src="../assets/astore.svg"
                alt=""
                @click="
                  window.location.href =
                    'https://apps.apple.com/ro/app/dib8/id1572242341'
                "
              />
              <img
                src="../assets/gplay.svg"
                alt=""
                @click="
                  window.location.href =
                    'https://play.google.com/store/apps/details?id=com.dib8.app'
                "
              />
            </div>
            <img
              class="presentation-img"
              src="../assets/presentation.png"
              alt=""
            />
            <div class="presentation-items">
              <presentation-item
                title="Start a conversation"
                text="Feel free to start a conversation about anything and see it grow everyday"
              >
                <img class="desktop-icon" src="../assets/start.png" alt="" />
              </presentation-item>
              <div class="mobile-height-space"></div>
              <presentation-item
                title="Contribute"
                text="Express your opinions in conversations that you find interesting"
              >
                <img
                  class="desktop-icon"
                  src="../assets/contribute.png"
                  alt=""
                />
              </presentation-item>
              <div class="mobile-height-space"></div>
              <presentation-item
                title="Listen"
                text="Listen to conversations as you would listen to a collaborative podcast. Interact with a message in order to switch the track. A conversation can have an unlimited number of tracks and can be <b>listened in many ways</b> without changing the context."
              >
                <img class="desktop-icon" src="../assets/listen.png" alt="" />
              </presentation-item>
              <div class="mobile-height-space"></div>
              <policies></policies>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import WelcomeCard from "../components/WelcomeCard.vue";
import Policies from "../components/Policies.vue";
import PresentationItem from "../components/PresentationItem.vue";
export default {
  components: {
    WelcomeCard,
    Policies,
    PresentationItem,
  },
  data() {
    return {
      window: window,
    };
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 600px) {
  .logo {
    height: 5.125rem;
  }
}

@media (max-width: 600px) {
  .logo {
    height: 3.625rem;
  }
}

.presentation-img {
  height: 41.75rem;
}

.logo-container {
  text-align: center;
  margin-top: 2rem;
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: hidden;
}

.mini-container {
  padding-top: 10vh;
}

.mr-16 {
  margin-right: 1rem;
}

.right-container {
  background-color: #e5f3f7;
  min-height: 100vh;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .presentation-container-desktop {
    display: flex;
    justify-content: center;
    .presentation-column {
      padding-top: 2rem;
      padding-left: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .presentation-items {
        max-width: 30rem;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .presentation-container-desktop {
    display: none;
  }
}

@media (min-width: 1200px) {
  .presentation-container-mobile {
    display: none;
  }
}

@media (max-width: 1200px) {
  .presentation-container-mobile {
    text-align: center;
    .title {
      text-align: center;
      padding-top: 1rem;
      font-family: NunitoLight;
      font-size: 1rem;
    }
    .social-media-mobile {
      padding-top: 1rem;
      padding-bottom: 1rem;
      img {
        width: 9rem;
        margin: 0.5rem;
        cursor: pointer;
      }
    }
    .presentation-items {
      padding: 10%;
    }

    .mobile-height-space {
      height: 3rem;
    }
  }
}
</style>

